import { WorkflowApiProvider } from '@rf-smart-for-oraclecloud/wf-engine';
import { UserStore } from '@rf-smart-for-oraclecloud/platform-ui';

const useMockedData = import.meta.env.VITE_USE_MOCK_API === 'true';
const baseUrl = useMockedData
    ? import.meta.env.VITE_MOCK_API_URL
    : import.meta.env.VITE_ORACLE_API_URL;

export function createOracleApiProvider(
    userContext: UserStore,
): WorkflowApiProvider {
    return {
        name: 'Oracle Api Provider',
        category: 'Oracle',
        description: 'Oracle Api Provider',
        type: 'oracle',
        send: async (request) => {
            const { path, body, method } = request;
            const { getAccessToken, organization, tenant } = userContext;
            const token = await getAccessToken();

            let headers: Record<string, string> = {
                Authorization: `Bearer ${token}`,
                organization: organization?.code ?? '',
                tenant: tenant?.code ?? '',
            };

            const content =
                body === undefined ? undefined : JSON.stringify(body);
            if (content !== undefined) {
                headers = { ...headers, 'Content-Type': 'application/json' };
            }

            return fetch(`${baseUrl}/${path}`, {
                method,
                body: content,
                headers,
            });
        },
    };
}
