import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { useLogStore } from '@@/hooks/useLogStore';
import { WorkflowDisplay } from '../run/WorkflowDisplay';

export function DebugRunner() {
    const { runId, stateId } = useParams();
    const run = useLogStore(
        useShallow((state) => state.snapshots[runId ?? '']),
    );
    const navigate = useNavigate();
    const handleClose = () => navigate('..', { replace: true });
    const snapshot = run ? run[stateId ?? ''] : undefined;

    if (snapshot === undefined) {
        return 'Unable to resolve workflow';
    }

    return (
        <WorkflowDisplay
            workflow={snapshot.workflowAggregate}
            peristenceSnapshot={snapshot.snapshot}
            onClose={handleClose}
        />
    );
}
