export function Section({
    children,
    bottomBorder = true,
    rightBorder = true,
    grow = false,
}: SectionProps) {
    return (
        <div
            style={{
                padding: '1rem',
                borderRight: rightBorder ? '1px solid var(--border-color)' : '',
                borderBottom: bottomBorder
                    ? '1px solid var(--border-color)'
                    : '',
                flexGrow: grow ? '1' : '',
            }}
        >
            {children}
        </div>
    );
}

export function SectionHeader({
    children,
    bottomBorder,
    rightBorder,
    grow,
}: SectionProps) {
    return (
        <Section
            bottomBorder={bottomBorder}
            rightBorder={rightBorder}
            grow={grow}
        >
            <h4>{children}</h4>
        </Section>
    );
}

type SectionProps = Readonly<
    React.PropsWithChildren<{
        grow?: boolean;
        bottomBorder?: boolean;
        rightBorder?: boolean;
    }>
>;
