import {
    DebugSnapshot,
    WorkflowEvent,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { insertLogs, insertSnapshots } from '../hooks/useLogStore';

export function postLogs(debugEvents: Array<WorkflowEvent>) {
    // eslint-disable-next-line no-console
    console.log('postLogs', debugEvents.length);
    insertLogs(debugEvents);
}

export function postSnapshots(debugSnapshots: Array<DebugSnapshot>) {
    // eslint-disable-next-line no-console
    console.log('postSnapshots', debugSnapshots.length);
    insertSnapshots(debugSnapshots);
}
