import { WorkflowApiProvider } from '@rf-smart-for-oraclecloud/wf-engine';

const baseUrl = import.meta.env.VITE_MOCK_API_URL;

export function createMockApiProvider(): WorkflowApiProvider {
    return {
        name: 'Mock Api Provider',
        category: 'Mock Api Provider',
        description: 'Mock Api Provider',
        type: 'mock',
        send: (request) => fetch(`${baseUrl}/${request.path}`, request),
    };
}
