import {
    AppConfig,
    AppProvider,
    AuthConfig,
    UrlConfig,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { routes } from './routes';

const auth: AuthConfig = {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    scope: import.meta.env.VITE_AUTH0_SCOPE,
};

const urls: UrlConfig = {
    basename: import.meta.env.BASE_URL,
    accountsUri: import.meta.env.VITE_ACCOUNTS_API_URL,
    workflowsUri: import.meta.env.VITE_WORKFLOW_API_URL,
    oracleUri: import.meta.env.VITE_ORACLE_API_URL,
    mockUri: import.meta.env.VITE_MOCK_API_URL,
    sharedDomain: import.meta.env.VITE_SHARED_DOMAIN,
};

const appConfig: AppConfig = { auth, urls };

export function App() {
    return <AppProvider routes={routes} appConfig={appConfig} />;
}
