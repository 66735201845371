import { Page } from '@rf-smart-for-oraclecloud/ui';
import './styles.css';

export function ContentLayout({ title, children }: BodyProps) {
    return (
        <Page>
            <Page.Header>
                <h1>{title}</h1>
            </Page.Header>
            <Page.Body>{children}</Page.Body>
        </Page>
    );
}

export function TwoColumnContent({ left, right }: TwoColumnContentProps) {
    return (
        <div className="wf-list__content-two-column">
            <div className="wf-list__content-left">{left}</div>
            <div className="wf-list__content-right">{right}</div>
        </div>
    );
}

export function OneColumnContent({ children }: OneColumnContentProps) {
    return children;
}

type BodyProps = Readonly<
    React.PropsWithChildren<{
        title: string;
    }>
>;

type TwoColumnContentProps = Readonly<{
    left: React.ReactNode;
    right: React.ReactNode;
}>;

type OneColumnContentProps = Readonly<React.PropsWithChildren>;
