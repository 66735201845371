import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { WorkflowEvent } from '@rf-smart-for-oraclecloud/wf-engine';
import { ListTable, ListTableColumn } from '@rf-smart-for-oraclecloud/wf-web';
import { resetLogs, useLogStore } from '@@/hooks/useLogStore';
import { useAppIntl } from '@@/intl';

interface WorkflowRun {
    id: string;
    workflowName: string;
    timestamp: Date;
}

const runListColumns: ListTableColumn<WorkflowRun>[] = [
    {
        name: 'Id',
        header: true,
        value: (run: WorkflowRun) => run.id,
    },
    {
        name: 'Name',
        header: true,
        value: (run: WorkflowRun) => run.workflowName,
    },
    {
        name: 'Timestamp',
        header: true,
        value: (run: WorkflowRun) => run.timestamp.toLocaleString(),
    },
];

export function SessionAnalyzer() {
    const [search, setSearch] = useSearchParams();
    const runId = search.get('runId') ?? '';
    const logs = useLogStore(useShallow((state) => state.logs));
    const setRunId = (id: string) =>
        setSearch({
            runId: id,
        });

    const items = useMemo(
        () =>
            Object.entries(logs).map<WorkflowRun>(([id, events]) => ({
                id,
                workflowName: events[0].workflowName,
                timestamp: events[0].timestamp,
            })),
        [logs],
    );
    const { sessionAnalyzer } = useAppIntl();

    function handleReset() {
        resetLogs();
    }

    if (runId) {
        return (
            <WorkflowRunDetail run={logs[runId]} onBack={() => setRunId('')} />
        );
    }

    return (
        <>
            <button type="button" onClick={handleReset}>
                {sessionAnalyzer.reset()}
            </button>
            <ListTable
                label="RunList"
                columns={runListColumns}
                items={items}
                idSelector={(item) => item.id}
                onSelect={(item) => setRunId(item.id)}
            />
        </>
    );
}

const runDetailColumns: ListTableColumn<WorkflowEvent>[] = [
    {
        name: 'Id',
        header: true,
        value: (run: WorkflowEvent) => run.id,
    },
    {
        name: 'ActivityId',
        header: true,
        value: (run: WorkflowEvent) => run.activityId,
    },
    {
        name: 'ActivityName',
        header: true,
        value: (run: WorkflowEvent) => run.activityName,
    },
    {
        name: 'Name',
        header: true,
        value: (run: WorkflowEvent) => run.name,
    },
    {
        name: 'Timestamp',
        header: true,
        value: (run: WorkflowEvent) => run.timestamp.toLocaleString(),
    },
];

interface WorkflowRunDetailProps {
    run: WorkflowEvent[];
    onBack: () => void;
}

function WorkflowRunDetail({ run, onBack }: Readonly<WorkflowRunDetailProps>) {
    const { sessionAnalyzer } = useAppIntl();
    const [search, setSearch] = useSearchParams();
    const eventId = search.get('eventId') ?? '';
    const setEventId = (id: string) =>
        setSearch({
            runId: run[0].runId,
            eventId: id,
        });
    const eventMap = useMemo(
        () =>
            run.reduce(
                (result, item) => ({
                    ...result,
                    [item.id]: item,
                }),
                {} as { [id: string]: WorkflowEvent },
            ),
        [run],
    );
    const event = eventMap[eventId];

    if (event) {
        return <EventDetail event={event} onBack={() => setEventId('')} />;
    }

    return (
        <div>
            <button type="button" onClick={() => onBack()}>
                {sessionAnalyzer.back()}
            </button>
            <h2>{run[0].runId}</h2>
            <ListTable
                label="EventList"
                columns={runDetailColumns}
                items={run}
                idSelector={(item) => item.id}
                onSelect={(item) => setEventId(item.id)}
            />
        </div>
    );
}

interface EventDetailProps {
    event: WorkflowEvent;
    onBack: () => void;
}

function EventDetail({ event, onBack }: Readonly<EventDetailProps>) {
    const navigate = useNavigate();
    const { sessionAnalyzer } = useAppIntl();

    const handleDebug = () => {
        navigate(`/debug/${event.runId}/${event.stateId}`);
    };

    return (
        <div>
            <button type="button" onClick={() => onBack()}>
                {sessionAnalyzer.back()}
            </button>
            <button type="button" onClick={handleDebug}>
                {sessionAnalyzer.openDebugger()}
            </button>
            <pre>{format(event)}</pre>
        </div>
    );
}

function format(obj: any) {
    return JSON.stringify(obj, null, 2);
}
