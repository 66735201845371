import { useState } from 'react';
import { Search } from '@rf-smart-for-oraclecloud/ui';
import { debounce } from '@rf-smart-for-oraclecloud/platform-ui';
import { useRunData } from '@@/hooks/useRunData';
import { useAppIntl } from '@@/intl';
import { FilteredWorkflowList } from './FilteredWorkflowList';
import { Section, SectionHeader } from './Section';

export function WorkflowList() {
    const [filter, setFilter] = useState('');
    const { profiles, loading } = useRunData({ filter });
    const { workflowList } = useAppIntl();

    const handleSubmit = setFilter;
    const handleClear = () => setFilter('');
    const handleChange = debounce(setFilter);

    return (
        <>
            <SectionHeader>{workflowList.allWorkflows()}</SectionHeader>
            <Section>
                <Search
                    aria-label={workflowList.searchWorkflows()}
                    onSubmit={handleSubmit}
                    onClear={handleClear}
                    onChange={handleChange}
                    placeholder={workflowList.searchWorkflows()}
                />
            </Section>
            <Section bottomBorder={false} grow>
                <FilteredWorkflowList profiles={profiles} loading={loading} />
            </Section>
        </>
    );
}
