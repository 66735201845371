import { StateCreator } from 'zustand';
import { RunDataStore } from '../types';

export const createRunDataSlice: StateCreator<RunDataStore> = (setState) => ({
    runData: {
        initialized: false,
        loading: false,
        workflows: {},
        profiles: [],
    },
    setRunData: (args) =>
        setState((state) => ({
            runData: {
                ...state.runData,
                ...args,
            },
        })),
});
