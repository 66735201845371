import { useMemo } from 'react';
import { Layout } from '@rf-smart-for-oraclecloud/ui';
import { MdOutlineImage } from 'react-icons/md';
import {
    AppHeader,
    AppMenu,
    useUser,
    type MenuItem,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { useAppIntl } from '@@/intl';
import { ContentLayout, OneColumnContent } from './ContentLayout';
import { WorkflowList } from './WorkflowList';
import './style.css';

const app = 'Workflows';
const area = 'Workflows';

const accountsBaseUrl =
    import.meta.env.BASE_URL === '/'
        ? import.meta.env.VITE_ACCOUNTS_URL
        : `${window.location.origin}/accounts`;
const workflowAdminBaseUrl =
    import.meta.env.BASE_URL === '/'
        ? import.meta.env.VITE_WORKFLOW_ADMIN_URL
        : `${window.location.origin}/workflow-admin`;

const menuItems: (Omit<MenuItem, 'onClick'> & { url: string })[] = [
    {
        icon: MdOutlineImage,
        label: 'Execute',
        url:
            import.meta.env.BASE_URL === '/'
                ? import.meta.env.VITE_WORKFLOW_EXECUTE_URL
                : `${window.location.origin}/workflow-runner/`,
    },
    {
        icon: MdOutlineImage,
        label: 'Configuration',
        url: `${workflowAdminBaseUrl}/config`,
    },
    {
        icon: MdOutlineImage,
        label: 'Profiles',
        url: `${workflowAdminBaseUrl}/profiles`,
    },
    {
        icon: MdOutlineImage,
        label: 'Sessions',
        url: `${workflowAdminBaseUrl}/sessions`,
    },
    {
        icon: MdOutlineImage,
        label: 'Settings',
        url: `${workflowAdminBaseUrl}/settings`,
    },
];

export function ListPage() {
    const { logout } = useUser();
    const intl = useAppIntl();

    const items = useMemo(() => menuItems, []);

    const appMenuItems = [
        {
            name: intl.menu.app_accounts(),
            route: accountsBaseUrl,
        },
        {
            name: intl.menu.app_workflowAdmin(),
            route: workflowAdminBaseUrl,
        },
    ];

    return (
        <Layout>
            <Layout.Header>
                <AppHeader
                    app={app}
                    menuItems={appMenuItems}
                    onLogout={logout}
                    tenantRequired
                />
            </Layout.Header>
            <Layout.Aside>
                <AppMenu items={items} />
            </Layout.Aside>
            <Layout.Body>
                <ContentLayout title={area}>
                    <OneColumnContent>
                        <WorkflowList />
                    </OneColumnContent>
                </ContentLayout>
            </Layout.Body>
        </Layout>
    );
}
