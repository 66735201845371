import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const root = document.getElementById('root')!;

const strictModeDisabled =
    import.meta.env.VITE_DISABLE_DEV_STRICT_MODE === 'true';

// eslint-disable-next-line
const RenderedApp = strictModeDisabled ? (
    <App />
) : (
    <StrictMode>
        <App />
    </StrictMode>
);

createRoot(root).render(RenderedApp);
