import {
    DebugSnapshot,
    WorkflowEvent,
    createEngine,
    createPlugin,
} from '@rf-smart-for-oraclecloud/wf-engine';
import customizations from '@rf-smart-for-oraclecloud/wf-plugin-custom';
import oracle from '@rf-smart-for-oraclecloud/wf-plugin-oracle';
import { builtIn, createAppender } from '@rf-smart-for-oraclecloud/wf-web';
import { UserStore } from '@rf-smart-for-oraclecloud/platform-ui';
import { createOracleApiProvider } from './api/workflowApiProviders.ts/oracle';
import { createMockApiProvider } from './api/workflowApiProviders.ts/mock';
import { postSnapshots, postLogs } from './api/logs';

export function createAppEngine(userContext: UserStore) {
    return createEngine({
        plugins: [
            oracle,
            customizations,
            builtIn,
            createPlugin({
                apiProviders: [
                    createOracleApiProvider(userContext),
                    createMockApiProvider(),
                ],
            }),
        ],
        eventAppenderFactory: () =>
            createAppender<WorkflowEvent>({
                onFlush: postLogs,
                bufferSize: 10,
            }),
        snapshotAppenderFactory: () =>
            createAppender<DebugSnapshot>({
                onFlush: postSnapshots,
                bufferSize: 3,
            }),
    });
}
