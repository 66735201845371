import { createStore } from 'zustand/vanilla';
import { useStore as useZustandStore } from 'zustand';
import { ApplicationStore } from '../types';
import { createRunDataSlice } from './runData';

const store = createStore<ApplicationStore>((...api) => ({
    ...createRunDataSlice(...api),
}));

export const useStore = <TData>(selector: (state: ApplicationStore) => TData) =>
    useZustandStore(store, selector);

export const { getState, setState } = store;
