import { RouteObject } from 'react-router-dom';
import { ListPage } from './areas/list';
import { WorkflowRunner } from './areas/run';
import { SessionAnalyzer } from './areas/SessionAnalyzer/SessionAnalyzer';
import { DebugRunner } from './areas/debug';

export const routes: RouteObject[] = [
    {
        index: true,
        element: <ListPage />,
    },
    {
        path: 'run/:profileId/:profileEntryId',
        element: <WorkflowRunner />,
    },
    {
        path: 'debug/:runId/:stateId',
        element: <DebugRunner />,
    },
    {
        path: 'sessionAnalyzer',
        element: <SessionAnalyzer />,
    },
];
