import { createStore } from 'zustand/vanilla';
import { persist } from 'zustand/middleware';
import { useStore } from 'zustand';
import { DebugStore } from '../types';

const store = createStore(
    persist<DebugStore>(
        () => ({
            debug: false,
        }),
        {
            name: 'DebugStore',
        },
    ),
);

globalThis.setDebug = (debug: boolean) =>
    store.setState(() => ({
        debug,
    }));

export const useDebug = () => useStore(store, (s) => s.debug);
