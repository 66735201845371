import {
    DebugSnapshot,
    WorkflowEvent,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';

const storage: StateStorage = {
    getItem: async (name: string): Promise<string | null> =>
        (await get(name)) ?? null,
    setItem: async (name: string, value: string): Promise<void> => {
        await set(name, value);
    },
    removeItem: async (name: string): Promise<void> => {
        await del(name);
    },
};

const useLogStore = create(
    persist<LogStore>(
        (_, _1) => ({
            logs: {},
            snapshots: {},
        }),
        {
            name: 'logs',
            storage: createJSONStorage(() => storage),
        },
    ),
);

function resetLogs() {
    useLogStore.setState({
        logs: {},
        snapshots: {},
    });
}

function insertLogs(debugEvents: Array<WorkflowEvent>) {
    const { logs } = useLogStore.getState();
    const { runId } = debugEvents[0];
    const existing = logs[runId] ?? [];
    useLogStore.setState({
        logs: {
            ...logs,
            [runId]: [...existing, ...debugEvents],
        },
    });
}

function insertSnapshots(debugSnapshots: Array<DebugSnapshot>) {
    const { snapshots } = useLogStore.getState();
    const { runId } = debugSnapshots[0];
    const existing = snapshots[runId] ?? {};
    const updated = {
        ...existing,
        ...debugSnapshots.reduce(
            (result, item) => ({
                ...result,
                [item.stateId]: item,
            }),
            {} as { [stateId: string]: DebugSnapshot },
        ),
    };
    useLogStore.setState({
        snapshots: {
            ...snapshots,
            [runId]: updated,
        },
    });
}

export { useLogStore, resetLogs, insertLogs, insertSnapshots };

interface LogStore {
    logs: {
        [runId: string]: Array<WorkflowEvent>;
    };
    snapshots: {
        [runId: string]: {
            [stateId: string]: DebugSnapshot;
        };
    };
}
