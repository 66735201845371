import { WorkflowDisplay as WebWorkflowDisplay } from '@rf-smart-for-oraclecloud/wf-web';
import {
    PersistenceSnapshot,
    WorkflowAggregate,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { useEngine } from '@@/hooks/useEngine';
import { useDebug } from '@@/hooks/useDebug';

export function WorkflowDisplay({
    workflow,
    peristenceSnapshot,
    onClose,
}: Readonly<WorkflowDisplayProps>) {
    const debug = useDebug();
    const engine = useEngine();

    return (
        <WebWorkflowDisplay
            workflow={workflow}
            engine={engine}
            debug={debug}
            peristenceSnapshot={peristenceSnapshot}
            onClose={onClose}
        />
    );
}

interface WorkflowDisplayProps {
    workflow: WorkflowAggregate;
    peristenceSnapshot?: PersistenceSnapshot;
    returnTo?: string;
    onClose: () => void;
}
