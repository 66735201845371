import {
    useWfIntl,
    defineMessages,
} from '@rf-smart-for-oraclecloud/platform-ui';

export const definedMessages = {
    menu: defineMessages({
        app_accounts: {
            defaultMessage: 'Accounts',
            description: 'name of accounts app',
        },
        app_workflowAdmin: {
            defaultMessage: 'Workflows',
            description: 'name of workflows app',
        },
    }),
    sessionAnalyzer: defineMessages({
        back: {
            defaultMessage: 'Back',
            description: 'Back',
        },
        openDebugger: {
            defaultMessage: 'Open Debugger',
            description: 'Open Debugger',
        },
        reset: {
            defaultMessage: 'Reset',
            description: 'Reset',
        },
    }),
    workflowItemDisplay: defineMessages({
        start: {
            defaultMessage: 'Start',
            description: 'Start',
        },
        sync: {
            defaultMessage: 'Sync',
            description: 'Sync',
        },
        syncing: {
            defaultMessage: 'Syncing...',
            description: 'Syncing',
        },
    }),
    workflowList: defineMessages({
        allWorkflows: {
            defaultMessage: 'All Workflows',
            description: 'All Workflows',
        },
        searchWorkflows: {
            defaultMessage: 'Search Workflows',
            description: 'Search Workflows',
        },
    }),
};

export const useAppIntl = () => useWfIntl(definedMessages);
