import { WorkflowActivitySpec } from '@rf-smart-for-oraclecloud/wf-engine';
import {
    ApiUrl,
    Profile,
    defineApi,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { WorkflowAggregateResponse, WorkflowDetailResponse } from '../types';

const useMock = import.meta.env.VITE_USE_MOCK_API === 'true';

export const useWorkflowsApi = defineApi((api) => ({
    getAggregate: async () => {
        const apiResponse = await api.get<WorkflowAggregateResponse>(
            `workflows/aggregate`,
            {
                api: useMock ? ApiUrl.Mock : ApiUrl.Workflows,
            },
        );

        return {
            profiles: mapProfiles(apiResponse.profiles),
            workflows: mapWorkflows(apiResponse.workflows),
        };
    },
}));

function mapProfiles(profiles: Profile[]): Profile[] {
    return profiles.map((p) => ({
        ...p,
        entries: p.entries.map((e) => ({
            ...e,
            pluginConfigs: [],
        })),
    }));
}

function mapWorkflows(
    workflows: Record<string, WorkflowDetailResponse>,
): Record<string, WorkflowActivitySpec> {
    return Object.keys(workflows).reduce(
        (result, id) => ({
            ...result,
            [id]: {
                id,
                type: 'workflow',
                category: workflows[id].category,
                name: workflows[id].name,
                description: workflows[id].description,
                depends: workflows[id].latestRevision.dependencies,
                ...workflows[id].latestRevision.data,
            },
        }),
        {} as Record<string, WorkflowActivitySpec>,
    );
}
