import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '@rf-smart-for-oraclecloud/platform-ui';
import { useRunData } from '@@/hooks/useRunData';
import { createAggregate } from '@@/util/createAggregate';
import { WorkflowDisplay } from './WorkflowDisplay';

export function WorkflowRunner() {
    const { loading, workflows, profiles } = useRunData();
    const { profileId, profileEntryId } = useParams();
    const navigate = useNavigate();
    const handleClose = () => navigate('..', { replace: true });

    const aggregate = useMemo(() => {
        const profile = profiles.find((p) => p.id === profileId);
        const entry = profile?.entries.find((e) => e.id === profileEntryId);

        return createAggregate(entry, workflows);
    }, [profiles, workflows, profileId, profileEntryId]);

    if (loading) {
        return <Loading isLoading />;
    }

    if (aggregate === undefined) {
        return 'Unable to resolve workflow';
    }

    return <WorkflowDisplay workflow={aggregate} onClose={handleClose} />;
}
